import React from "react";

import Layout from "../components/Layout";
import SEO from "../components/SEO";
import UnderConstruction from "../components/UnderConstruction";

const AboutPage = () => (
  <Layout>
    <SEO title="FIND Mapping Limited" />
    <UnderConstruction />
  </Layout>
);

export default AboutPage;