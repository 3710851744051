import React from 'react';

const ConstructionProp = props => {

  return (
    <section className="value-prop">
      <div className="value-prop-content">
        <div className="main-message">
          <h1>The FIND website is current under construction</h1>
          <p className="main-subtitle">However it is still service as normal for all your data requirements.</p>
          <p className="main-subtitle">Call us on 0203 0027449, if you have any questions.</p>
        </div>
        <div className="underConstruction-photo"></div>
      </div>
      <p className="main-items">--- Utility reports --- Marine charts --- Bombrisk reports --- Planning maps --- Wall maps --- Property rebuild costs ---</p>
    </section>
  )
};

export default ConstructionProp;